import React from "react";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import v1 from "../../Assets/hydroponic video/review1.mp4";
import v2 from "../../Assets/hydroponic video/review2.mp4";
import v3 from "../../Assets/hydroponic video/review3.mp4";
import v4 from "../../Assets/hydroponic video/review4.mp4";
import v5 from "../../Assets/hydroponic video/review5.mp4";
import v6 from "../../Assets/hydroponic video/santosh_chaudhary_review.mp4";

import Avtar from "../../Assets/map/bharat.webp";
import Avtar1 from "../../Assets/map/dadhi.webp";
import Avtar2 from "../../Assets/map/map11.webp";

function CustomerReview(props) {
  const [reviewData, setReviewData] = useState();
  useEffect(() => {
    console.log(props.reviewData);
    setReviewData(props.reviewData);
  }, [props.reviewData]);

  var vid = [
    { src: v6 },
    { src: v5 },
    { src: v4 },
    { src: v3 },
    { src: v2 },
    { src: v1 },
  ];
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Customer Reviews
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Customer Reviews
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-5">
            <h2 className="text-muted text-center">
              See what other say about us
            </h2>
            <h3 className="text-center">TESTIMONIALS</h3>
          </Col>
        </Row>

        <Row className="p-3">
          <Col sm={6} lg={4} className="p-3">
            <Row className="p-3 ">
              <Col className="text-end">
                <img src={Avtar} alt="farming" className="rounded-circle " />
              </Col>
              <Col className="p-3">
                Royal Green House provided me with excellent knowledge of
                hydroponic farming while successfully building my DFT project.
                <br /> - Bharath,Hydro Garden, Bangalore
                <div>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star-half-stroke text-warning"></i>
                  <i class="fa-regular fa-star text-warning"></i>
                </div>
              </Col>
            </Row>
          </Col>

          <Col sm={6} lg={4} className="p-3">
            <Row className="p-3 ">
              <Col className="text-end">
                <img
                  src={Avtar1}
                  alt="hydroponics"
                  className="rounded-circle "
                />
              </Col>
              <Col className="p-3">
                Royal Green House has greatly enhanced my understanding of
                hydroponic farming.
                <br /> - Joon shabbir,Woody wolf farmstead, Mumbai
                <div>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star-half-stroke text-warning"></i>
                  <i class="fa-regular fa-star text-warning"></i>
                </div>
              </Col>
            </Row>
          </Col>

          <Col sm={6} lg={4} className="p-3">
            <Row className="p-3 ">
              <Col className="text-end">
                <img
                  src={Avtar2}
                  alt="hydroponic farms"
                  className="rounded-circle"
                />
              </Col>
              <Col className="p-3">
                Royal Green House is the ultimate solution for hydroponic
                farming.
                <br /> - Pink Pearl, Jaipur
                <div>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star text-warning"></i>
                  <i class="fa-solid fa-star-half-stroke text-warning"></i>
                  <i class="fa-regular fa-star text-warning"></i>
                </div>
              </Col>
            </Row>
          </Col>

          {reviewData &&
            reviewData.map((elem, ind) => {
              return (
                <Col sm={6} lg={4} className="p-3">
                  <Row className="p-3 ">
                    <Col className="text-end">
                      <img
                        src={Avtar}
                        alt="hydroponic farming reviews"
                        className="rounded-circle"
                      />
                    </Col>

                    <Col className="p-3">
                      {elem.review}
                      <div>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star text-warning"></i>
                        <i class="fa-solid fa-star-half-stroke text-warning"></i>
                      </div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Row>

        <Row className="justify-content-center">
          {vid.map((video, index) => {
            return (
              <Col key={index} sm={6} lg={3} className="mb-4">
                <div className="text-center">
                  <iframe
                    src={video.src}
                    height="360"
                    title="Vimeo video"
                    allowFullScreen
                    className="rounded-4 p-2"
                  ></iframe>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

export default CustomerReview;
