import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import AgreeServicess from "../../Components/AgreeServicess";
import Enquiry from "../../Components/Enquiry";
import aboutImg from "../../Assets/aboutImg0.webp";
import aboutImg2 from "../../Assets/about2.webp";
import Certificate1 from "../../Assets/certificate1.webp";
import Certificate2 from "../../Assets/certificate2.webp";
import Certificate3 from "../../Assets/certificate3.webp";
import Certificate4 from "../../Assets/certificate4.webp";
import Certificate5 from "../../Assets/certificate5.webp";

function About() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              About Us
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right "></i>
              <a className="text-white text-decoration-none m-3">About Us</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <h1 className="text-green text-center m-4">Royal Green House</h1>
            <p className="m-3 text-justify">
              {" "}
              Established in the year 2014, Royal Green House Construction India
              Private Limited is a trusted name engaged in providing
              Construction Services for Commercial Farm Setup like Natural
              Ventilated Poly Houses, Fan & Pad Poly Houses and Shade Houses.
              Which are commonly used in agriculture industries. Use of latest
              technology and excellent workmanship make our services ahead of
              those rendered by our competitors.
            </p>
            <p className="m-3 text-justify">
              Our team of expert is successfully catering to the needs of
              various clients, and has completed projects on more than 250 acres
              of landscape. With deep understanding about hi-tech farming and
              climatic conditions of various regions of India, we bring forth
              most effective and profitable solutions for our clients. Our
              ultramodern facility helps us in molding pipes and carrying out
              other technical civil works effectively.
            </p>
            <p className="m-3 text-justify">
              Capitalizing on the ample knowledge from several advisors and
              agronomist also strategic partnership with various industry
              leaders, we have created an strong position for ourselves in the
              market. Under our advisors guidance and support, we are able to
              help our clients in various aspects including land selection &
              development, crop selection, suitable irrigation system and
              marketing of the crops.
            </p>
          </Col>
          <Col lg={6} className="p-3">
            <img
              src={aboutImg}
              alt="hydroponic farming at home"
              className="img-fluid p-3"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="p-3">
            <img
              src={aboutImg2}
              alt="hydroponic farming cost"
              className="img-fluid p-3"
            />
          </Col>
          <Col lg={6}>
            <p className="m-4 mt-5 text-justify">
              We are backed by rich industry experience in constructing
              unmatched quality poly houses using advanced technology. The
              design is such that makes our range ideal for safeguarding cut
              flowers such as gerbera, carnation, dutch roses and antiurium.
              Besides,having variety of other applications, the polyhouse is
              also suited for cultivation of exotic vegetables like cherry,
              tomato, colored capsicum, broccoli and several other medicinal
              plants.
            </p>
            <p className="m-4 text-justify">
              Available in various sizes and designs, our range can also be
              customized as per your specific requirements.
            </p>
            <ul className="m-3 text-justify">
              <li>
                Specialized in quality green house construction since 10+ years.
              </li>
              <li>
                Envoirnment controlled green houses like saw tooh span even span
                multi span.
              </li>
              <li>Full technical backup and skilled man power.</li>
              <li>
                All types of structural & green house accessories suppliers.
              </li>
              <li>90% Welding Less Structure.</li>
              <li>Skilled labour.</li>
              <li>On time Delivery.</li>
              <li>Structure height 6.5mtr.</li>
              <li>5 Layer Ginegar UV Stablized Polyfirm (imp).</li>
              <li>One Piece G.I. Gutter.</li>
            </ul>
          </Col>
        </Row>
        <Row className="m-5">
          <h2 className="text-center text-green">Our Certificates</h2>
          <Col lg={4}>
            <img
              src={Certificate1}
              alt="hydroponic farming in india"
              className="img-fluid p-3"
              style={{ width: "auto", height: "80%" }}
            />
          </Col>
          <Col lg={4}>
            <img
              src={Certificate2}
              alt="hydroponic farming near me"
              className="img-fluid p-3"
              style={{ width: "auto", height: "80%" }}
            />
          </Col>
          <Col lg={4}>
            <img
              src={Certificate3}
              alt="hydroponic farming"
              className="img-fluid p-3"
              style={{ width: "auto", height: "80%" }}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={4}>
            <img
              src={Certificate4}
              alt="hydroponic farm near me"
              className="img-fluid p-3"
              style={{ width: "auto", height: "80%" }}
            />
          </Col>
          <Col lg={4}>
            <img
              src={Certificate5}
              alt="hydroponic farms near me"
              className="img-fluid p-3"
              style={{ width: "auto", height: "80%" }}
            />
          </Col>
        </Row>
      </Container>
      <AgreeServicess />
      <Enquiry />
    </>
  );
}

export default About;
