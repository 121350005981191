import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import polyhouse_bog_img from "../../Assets/polyhouse_blog_img.webp";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import nft_blog_img from "../../Assets/nft_blog_img.jpg";
import "./blog.css";

function Nft_Hydroponics() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={nft_blog_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="NFT Hydroponic Farming"
        />
      </div>
      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">NFT Hydroponics</h1>
            <p>
              The Nutrient Film Technique (NFT) hydroponics is one of the most
              efficient and widely used hydroponic farming methods today. But
              what exactly is NFT hydroponics? Simply put, it is a system that
              delivers a continuous flow of nutrient-rich water directly to
              plant roots, ensuring rapid growth, high productivity, and
              sustainable farming practices.
            </p>
            <h2 className="pt-3">Understanding NFT Hydroponics</h2>
            <p>
              NFT hydroponics is a soilless farming method where a thin film of
              nutrient-rich water continuously flows through shallow channels,
              providing essential nutrients directly to plant roots. Unlike
              traditional soil-based farming, NFT hydroponic systems guarantee
              that plants receive the perfect balance of nutrients while
              maintaining oxygen availability, resulting in healthier root
              development and faster crop growth.
            </p>

            <h2 className="pt-3">
              How Does the Nutrient Film Technique (NFT) Hydroponics Work?
            </h2>
            <p>
              NFT hydroponic systems operate on a simple yet highly efficient
              principle:
            </p>
            <ol>
              <li>
                <span className="fw-bold">Reservoir and Pump:</span> A nutrient
                solution is stored in a reservoir and pumped to the highest
                point of the NFT channels.
              </li>
              <li>
                <span className="fw-bold">Thin Film Flow:</span> The
                nutrient-rich solution moves through sloped channels, forming a
                thin film that delivers nutrients to the roots while allowing
                oxygen absorption.
              </li>
              <li>
                <span className="fw-bold">Root Exposure:</span> Since the roots
                are never fully submerged, they absorb oxygen directly from the
                air, ensuring faster and healthier growth.
              </li>
              <li>
                <span className="fw-bold">Drainage & Recirculation:</span> The
                excess nutrient solution flows back into the reservoir, where it
                is filtered and reused, reducing waste and ensuring consistent
                nutrient supply.
              </li>
            </ol>
            <p>
              The key to a successful NFT hydroponic system is maintaining
              optimal flow rates, nutrient balance, and oxygen levels to
              maximize plant growth and productivity.
            </p>
            <h2 className="pt-3">Benefits of NFT Hydroponics</h2>
            <p>
              Adopting NFT hydroponics offers several advantages for both home
              growers and commercial farmers:
            </p>
            <ol>
              <li>
                <span className="fw-bold">Water and Nutrient Efficiency:</span>{" "}
                NFT hydroponics recirculates the nutrient solution, reducing
                water wastage by up to 90% compared to traditional soil farming.
              </li>
              <li>
                <span className="fw-bold">Faster Growth and Higher Yield:</span>{" "}
                The constant nutrient supply and high oxygen levels in NFT
                hydroponics lead to 30–50% faster growth rates than soil-based
                cultivation.The constant nutrient supply and high oxygen levels
                in NFT hydroponic systems lead to 30–50% faster growth rates
                than soil-based cultivation.
              </li>
              <li>
                <span className="fw-bold">Space-Saving & Scalable:</span> NFT
                hydroponic systems are compact and modular, making them perfect
                for urban farming, commercial hydroponic setups, and indoor
                gardening.
              </li>
              <li>
                <span className="fw-bold">
                  Minimal Risk of Soil-Borne Diseases:
                </span>{" "}
                Since NFT hydroponic farming is a soilless system, it eliminates
                common soil-related pests and diseases, reducing the need for
                pesticides and herbicides.
              </li>
              <li>
                <span className="fw-bold">Year-Round Farming Potential:</span>{" "}
                With NFT hydroponics, you can grow crops all year round,
                ensuring consistent yields regardless of weather conditions.
              </li>
            </ol>
            <h2 className="pt-3">
              Start Your NFT Hydroponic Farming Journey with RGCPL Today!
            </h2>
            <p>
              With its high efficiency, sustainability, and scalability, NFT
              hydroponics is transforming modern agriculture. Whether you are a
              home grower or a commercial farmer, investing in NFT hydroponic
              systems offers a cost-effective and sustainable way to maximize
              crop production.
            </p>
            <p>
              Looking to set up your own NFT hydroponic system? Contact Royal
              Green House Construction (RGCPL) for expert solutions in designing
              and implementing top-quality NFT hydroponic systems to achieve
              maximum efficiency and profitability.
            </p>
            <p className="fw-bold">Get in Touch Today!</p>
            <i>
              Explore the future of NFT hydroponics with RGCPL and take your
              hydroponic farming venture to the next level!
            </i>
            <div className="d-flex justify-content-center mt-4">
              <Link to="/RequestAQuote">
                <Button variant="success" size="lg">
                  Get a Free Quote Now!
                </Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Nft_Hydroponics;
