import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function Enquiry() {
  return (
    <>
      <Container fluid>
        <Row className="bglightGreen">
          <Col lg={6} className="p-5">
            <h6 className="text-white m-4 mx-4 px-2 fw-bold">
              We Are Available
            </h6>
            <h2 className="text-white m-4 mx-4 px-2 fw-bold">
              Complete One Stop Solutions For The 21st Century Hydroponics Farms
            </h2>
            {/* <p className='text-white m-4 mx-4 px-2'>
                        Get customized business plans & setup cost from our commercial hydroponic farming
                        </p> */}
            <p className="text-white m-4 mx-4 px-2">
              Hydroponic Farming can help grow 4x more plants in the same space
              as traditional farming! Enquire Now.
            </p>

            <Row>
              <Col lg={4} className="text-white">
                <h6 className="m-4 mx-4 px-2 text-center ">
                  <i class="fa-solid fa-people-roof fs-1 px-3 py-2"></i> <br />
                  Good Management
                </h6>
              </Col>
              <Col lg={4} className="text-white">
                <h6 className="m-4 mx-4 px-2 text-center">
                  <i class="fa-solid fa-handshake-angle fs-1 px-3 py-2"></i>{" "}
                  <br />
                  Fastest Solutions
                </h6>
              </Col>
              <Col lg={4} className="text-white">
                <h6 className="m-4 mx-4 px-2 text-center">
                  <i class="fa-solid fa-industry fs-1 px-3 py-2"></i> <br />{" "}
                  best returns on investment
                </h6>
              </Col>
            </Row>
            {/* <Row>
              <Col className="m-2">
                <div className="d-flex justify-content-between justify-content-sm-center my-4">
                  <a
                    href="https://www.youtube.com/channel/UCHkBnZS-kJXRYSUIUF2u2pA"
                    target="_blank"
                    aria-label="Visit our YouTube channel"
                  >
                    <i className="fa-brands fa-youtube fs-1 mx-2 text-white rotate"></i>
                  </a>

                  <a
                    href="https://www.instagram.com/royal_green_house_/"
                    target="_blank"
                    aria-label="Visit our Instagram profile"
                  >
                    <i className="fa-brands fa-instagram fs-1 mx-2 text-white rotate"></i>
                  </a>

                  <a
                    href="https://www.facebook.com/people/Royalgreen-House/pfbid06Ein7Yjsr32vBRywLq5RBQS3jcaVRxY1U81zwac1Ybf9MkRLBHw9SMojgPt4cDSCl/"
                    target="_blank"
                    aria-label="Visit our Facebook profile"
                  >
                    <i className="fa-brands fa-facebook fs-1 mx-2 text-white rotate"></i>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/royal-green-house-construction-i-pvt-ltd-692664268?original_referer="
                    target="_blank"
                    aria-label="Visit our Linkdin profile"
                  >
                    <i className="fa-brands fa-linkedin fs-1 mx-2 text-white rotate"></i>
                  </a>

                  <a
                    href="https://wa.me/+919960991166"
                    target="_blank"
                    aria-label="Contact us on WhatsApp"
                  >
                    <i className="fa-brands fa-whatsapp fs-1 mx-2 text-white rotate"></i>
                  </a>

                  <a
                    href="https://twitter.com/GreenhouseRoya1"
                    target="_blank"
                    aria-label="Visit our Twitter profile"
                  >
                    <i className="fa-brands fa-twitter fs-1 mx-2 text-white rotate"></i>
                  </a>
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col className="m-2">
                <div className="d-flex justify-content-between justify-content-sm-center my-4">
                  <a
                    href="https://www.youtube.com/channel/UCHkBnZS-kJXRYSUIUF2u2pA"
                    target="_blank"
                    aria-label="Visit our YouTube channel"
                  >
                    <i
                      className="fa-brands fa-youtube fs-1 mx-2 rotate"
                      style={{ color: "#FF0000" }}
                    ></i>
                  </a>

                  <a
                    href="https://www.instagram.com/royal_green_house_/"
                    target="_blank"
                    aria-label="Visit our Instagram profile"
                  >
                    <i
                      className="fa-brands fa-instagram fs-1 mx-2 rotate"
                      style={{
                        color: "#E1306C", 


                        
                      }}
                    ></i>
                  </a>

                  <a
                    href="https://www.facebook.com/people/Royalgreen-House/pfbid06Ein7Yjsr32vBRywLq5RBQS3jcaVRxY1U81zwac1Ybf9MkRLBHw9SMojgPt4cDSCl/"
                    target="_blank"
                    aria-label="Visit our Facebook profile"
                  >
                    <i
                      className="fa-brands fa-facebook fs-1 mx-2 rotate"
                      style={{ color: "#1877F2" }}
                    ></i>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/royal-green-house-construction-i-pvt-ltd-692664268?original_referer="
                    target="_blank"
                    aria-label="Visit our LinkedIn profile"
                  >
                    <i
                      className="fa-brands fa-linkedin fs-1 mx-2 rotate"
                      style={{
                        color: "#0A66C2", 
                      }}
                    ></i>
                  </a>

                  <a
                    href="https://wa.me/+919960991166"
                    target="_blank"
                    aria-label="Contact us on WhatsApp"
                  >
                    <i
                      className="fa-brands fa-whatsapp fs-1 mx-2 rotate"
                      style={{ color: "#25D366" }}
                    ></i>
                  </a>

                  <a
                    href="https://twitter.com/GreenhouseRoya1"
                    target="_blank"
                    aria-label="Visit our Twitter profile"
                  >
                    <i
                      className="fa-brands fa-twitter fs-1 mx-2 rotate"
                      style={{ color: "#1DA1F2" }}
                    ></i>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Form className="m-3 bg-white rounded-4">
              <Row className="m-3 p-3">
                <Col>
                  <Form className="m-3 bg-white rounded-4" id="contact-form">
                    <Row className="m-3 p-3">
                      <h4 className="m-3">GET IN TOUCH</h4>

                      <Col lg={12}>
                        <Form.Control
                          type="text"
                          placeholder="Enter Name"
                          className="p-2 m-2"
                          name="from_name"
                          id="from_name"
                        />
                      </Col>
                      <Col lg={12}>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          className="p-2 m-2"
                          name="user_email"
                        />
                      </Col>
                      <Col lg={12}>
                        <Form.Control
                          type="text"
                          placeholder="Phone"
                          className="p-2 m-2"
                          name="contact_number"
                        />
                      </Col>
                      <Col lg={12}>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter message..."
                          className="p-2 m-2"
                          name="message"
                          id="message"
                        />
                      </Col>
                      <Col lg={12}>
                        {/* <Button variant="outline-success" type="submit" className='p-2 m-2 mb-4 fw-bold'>
      <Form.Control type="submit" value="Send" />
         </Button> */}
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <p className="p-2 m-2 fw-bold">
                  Please be patient, We will get back to you.Our 24/7 Support,
                  General Inquireies Phone: +91-9960-991-166
                </p>
                <Button
                  variant="outline-success"
                  type="submit"
                  className="p-2 m-2 mb-4 fw-bold"
                >
                  {/* <Form.Control type="submit" value="Send" /> */}
                  <i class="fa-solid fa-circle-arrow-right fa-beat"></i> Sumbit
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Enquiry;
