import React, { useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import royalgreenlogo from "../../Assets/royalgreenlogo.webp";
import "./header.module.css";
import "../../App.css";
import GoogleTranslate from "../GoogleTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../../Contexts/CartContext"; // Import CartContext
import CartModal from "../CartModal"; // Adjust the path as needed

function Header() {
  const { cartItems } = useCart(); // Access cart items
  const [isCartVisible, setIsCartVisible] = useState(false); // State for modal visibility

  const handleCartClick = () => {
    setIsCartVisible(true); // Show the cart modal
  };

  const handleCartClose = () => {
    setIsCartVisible(false); // Close the cart modal
  };

  // Calculate total quantity in the cart
  const totalQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <div className="navbar-container">
      <Navbar expand="xl" className="navbar-light sticky-top">
        <Container fluid className="px-4">
          <Navbar.Brand href="#home" className="text-dark">
            <img src={royalgreenlogo} width="150" alt="Royal Green Logo" />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="text-dark"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Link
                as={Link}
                to="/"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                Home
              </Nav.Link>
              <NavDropdown
                title="Company"
                id="company-nav-dropdown"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                <NavDropdown.Item as={Link} to="/about">
                  About Us
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/customerReview">
                  Customer Review
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Services"
                id="services-nav-dropdown"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                <NavDropdown
                  title="Hydroponic Setup"
                  id="hydroponic-setup-nav-dropdown"
                  className="text-uppercase mx-1 fw-bold"
                >
                  <NavDropdown.Item as={Link} to="/nftAframe">
                    NFT A-Frame System
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/NftFlatbed">
                    NFT Flatbed System
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/DFTAFrame">
                    DFT A-Frame System
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/DWC">
                    DWC System
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/DutchBucket">
                    Dutch Bucket/Bato Bucket System
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Grow_Slab">
                    Grow Slab System
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Grow_bag">
                    Grow Bag System
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Polyhouse Setups"
                  id="polyhouse-setups-nav-dropdown"
                  className="text-uppercase mx-1 fw-bold"
                >
                  <NavDropdown.Item as={Link} to="/Nvph">
                    Naturally Ventilated Polyhouse
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/ClimateControlled">
                    Climate Control Polyhouse (Fan & Pad)
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Shade Net House Setups"
                  id="shade-net-house-nav-dropdown"
                  className="text-uppercase mx-1 fw-bold"
                >
                  <NavDropdown.Item as={Link} to="/Flat_Shade_Net_House">
                    Flat Shade Net House
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/TunnelShadeHouse">
                    Tunnel Shade Net House
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link
                  as={Link}
                  to="/LowcostGreenhouse"
                  className="text-uppercase mx-1 fw-bold"
                >
                  Low Cost Greenhouse Setup
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/RainProtectedWireRopeNetHouse"
                  className="text-uppercase mx-1 fw-bold"
                >
                  Rain Protected Wire Rope Net House
                </Nav.Link>
              </NavDropdown>

              <NavDropdown
                title="Products"
                id="products-nav-dropdown"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                <NavDropdown.Item as={Link} to="/LeafyGreens">
                  Leafy Greens
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/VineCrops">
                  Vine Crops
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Lettuces">
                  Lettuces
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/FreshHerbs">
                  Fresh Herbs
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Floriculture">
                  Floriculture
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Projects"
                id="projects-nav-dropdown"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                <NavDropdown.Item as={Link} to="/gallery">
                  Gallery
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/videos">
                  Videos
                </NavDropdown.Item>
              </NavDropdown>

              {/* <Nav.Link
                as={Link}
                to="/Subsidy"
                className="text-uppercase text-center mx-1 fw-bold"
              >
              
                Subsidy
              </Nav.Link> */}

              <Nav
                as={Link}
                to="https://www.ahft.co.in/"
                className="text-uppercase text-center mx-1 fw-bold"
                style={{
                  color: "green",
                  textDecoration: "none",
                  marginLeft: "10px",
                  padding: "10px 10px",
                }}
                target="_blank"
              >
                Enroll Hydroponic Course
              </Nav>
              <NavDropdown
                title="Shop"
                id="Automation_products"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                <NavDropdown.Item as={Link} to="/Automation_Products_Pens">
                  Pens
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Automation_Products_Monitors">
                  Monitors and Controllers
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Automation_Products_Meters">
                  Meters
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Automation_Products_Testing_and_Maintenance"
                >
                  Care, Testing, and Maintenance
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Greenhouse_Accessories">
                  Greenhouse Accessories
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                as={Link}
                to="/Blog"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                Blog
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/contact"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                <a
                  href="tel:+919960991166"
                  className="text-dark fw-bold text-decoration-none"
                >
                  Contact Us
                </a>
              </Nav.Link>

              <a
                href="https://wa.me/+919960991166"
                target="_blank"
                className="text-center mx-1 fw-bold"
              >
                <i className="fa-brands fa-whatsapp fs-1 mx-1 text-green rotate"></i>
              </a>

              <NavDropdown
                title="Select Language"
                className="text-uppercase text-center mx-1 fw-bold"
              >
                <div className="px-3 py-2">
                  <GoogleTranslate />
                </div>
              </NavDropdown>

              {/* Cart Icon */}
              <Nav.Link
                className="text-uppercase text-center mx-1 fw-bold position-relative"
                onClick={handleCartClick}
              >
                <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                {totalQuantity > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      background: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "2px 5px",
                      fontSize: "12px",
                    }}
                  >
                    {totalQuantity}
                  </span>
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Cart Modal */}
      {isCartVisible && <CartModal onClose={handleCartClose} />}
    </div>
  );
}

export default Header;
