import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import hydroponic_farm_img from "../../Assets/hydroponic_farm_img.webp";
import "./blog.css";
import "animate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function Hydroponic_Farming() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={hydroponic_farm_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "70%" }}
          alt="Hydroponics farm"
        />
      </div>

      <Row className="m-2">
        <Col
          className="p-4 textJustify"
          sm={12}
          md={9}
          xs={12}
          style={{ margin: "auto", fontSize: "1rem", lineHeight: "1.6" }}
          id="content"
        >
          <div
            id="blog1"
            className="border p-3"
            style={{ borderRadius: "10px" }}
          >
            <h1 className="text-center mb-3">Hydroponic Farming</h1>{" "}
            <p className="pb-3">
              Hydroponic farming is revolutionizing the way we grow food. As
              climate change accelerates, traditional farming practices are
              facing increasing challenges. Hydroponic farming, which involves
              growing plants without soil using nutrient-rich water, provides a
              sustainable and efficient solution. By leveraging advanced
              technology and controlled environments, hydroponic farming allows
              crops to thrive regardless of weather conditions, offering a
              climate-resilient alternative for food production.
            </p>
            <h4>What is Hydroponic Farming?</h4>
            <p>
              Hydroponic farming is an innovative agricultural method that
              enables plants to grow in a soil-free, nutrient-dense water
              solution. This technique provides multiple benefits over
              traditional farming, including:
              <ul>
                <li>Faster growth rates due to direct nutrient absorption.</li>
                <li>
                  Higher yields per square foot compared to soil-based farming.
                </li>
                <li>
                  Reduced water consumption, using up to 90% less water than
                  traditional methods.
                </li>
                <li>
                  Minimized dependency on arable land, making it ideal for urban
                  areas, rooftops, and regions with poor soil quality.
                </li>
              </ul>
            </p>
            <p className="pb-4">
              With hydroponic farming, crops receive essential nutrients
              directly from the water, ensuring optimal growth in any climate.
              This makes it an ideal solution for regions experiencing water
              scarcity or extreme weather conditions.
            </p>
            <h4>Hydroponic Farming and Climate Change:</h4>
            <p>
              Climate change is making conventional farming increasingly
              unpredictable, with droughts, floods, and erratic weather patterns
              affecting crop yields worldwide. Hydroponic farming presents a
              highly adaptable solution to these challenges. By growing crops in
              a controlled indoor environment, this method ensures consistent,
              year-round harvests regardless of external climate factors like
              temperature fluctuations, rainfall shortages, or natural
              disasters.
            </p>
            <p className="pb-4">
              Furthermore, hydroponic farming uses up to 90% less water than
              traditional farming, making it a key solution for areas struggling
              with water scarcity. With its ability to operate in any
              environment, hydroponic farming contributes significantly to
              global food security by offering a reliable and sustainable way to
              grow fresh produce even in the most challenging conditions.
            </p>
            <h4>Benefits of Hydroponic Farming:</h4>
            <ol>
              <li>
                <span className="fw-bold">Water Efficiency: </span>Requires up
                to 90% less water than conventional farming.
              </li>
              <li>
                <span className="fw-bold">Space Optimization: </span>Can be
                established in areas with limited space or poor soil quality.
              </li>
              <li>
                <span className="fw-bold">Year-Round Production: </span>
                Provides continuous crop production unaffected by weather
                changes.
              </li>
              <li>
                <span className="fw-bold">Higher Yields: </span>
                Maximizes crop output using fewer resources.
              </li>
              <li>
                <span className="fw-bold">Reduced Carbon Footprint: </span>
                Supports local food production, minimizing
                transportation-related emissions.
              </li>
              <li>
                <span className="fw-bold">Pest and Disease Control: </span>
                Controlled environments reduce reliance on pesticides and
                herbicides.
              </li>
              <li>
                <span className="fw-bold">Enhanced Nutrient Absorption: </span>
                Direct nutrient supply leads to healthier and more nutrient-rich
                crops.
              </li>
            </ol>
            <p className="pb-4">
              Beyond its environmental benefits, hydroponic farming is a
              game-changer for urban populations, providing access to fresh,
              pesticide-free produce grown locally. As concerns about food
              security and sustainability rise, hydroponic farming is proving to
              be a critical tool in addressing these global challenges.
            </p>
            <h4>Why Hydroponic Farming is the Future of Agriculture:</h4>
            <p>
              As we look to the future, hydroponic farming is set to play a
              pivotal role in feeding the world’s growing population while
              preserving natural resources. This high-tech, sustainable farming
              method is adaptable, resource-efficient, and environmentally
              friendly, making it an ideal solution for modern agriculture.
            </p>
            <p className="pb-4">
              At Royal Green House Construction (RGCPL), we specialize in
              designing and implementing advanced hydroponic farming systems
              tailored to meet diverse agricultural needs. From small-scale
              urban farms to large commercial operations, we provide end-to-end
              solutions, including system installation, technical support, and
              expert guidance.
            </p>
            <a href="http://www.royalgreenhouse.co.in/" target="_blank">
              <h4>Why Choose Hydroponic Farming with RGCPL?</h4>
            </a>
            <p>
              Partnering with RGCPL ensures that you are adopting a
              future-proof, sustainable approach to farming. Our hydroponic
              systems are designed to deliver maximum efficiency, reducing
              resource consumption while increasing crop yields. Whether you are
              growing for local markets, urban farms, or large-scale commercial
              production, we provide the technology and expertise needed to
              ensure your success.
            </p>
            <p>
              By choosing hydroponic farming with RGCPL, you gain access to
              cutting-edge farming techniques that prioritize sustainability,
              efficiency, and profitability. Our precision growing methods allow
              you to produce healthier, fresher, and environmentally responsible
              food, securing a competitive edge in an evolving agricultural
              landscape.
            </p>
            <p>
              Join the hydroponic farming revolution with RGCPL and take a step
              towards a more sustainable future!
            </p>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon icon={faPhone} className="me-2 text-success" />
              <span>
                Phone:{" "}
                <a
                  href="tel:+919960991166"
                  className="text-primary text-decoration-none"
                >
                  +91-9960-991-166
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="me-2 text-success"
              />
              <span>
                Email:{" "}
                <a
                  href="mailto:info@royalgreenhouse.co.in"
                  target="_blank"
                  className="text-primary text-decoration-none"
                >
                  info@royalgreenhouse.co.in
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faGlobe} className="me-2 text-success" />
              <span>
                Website:{" "}
                <a
                  href="http://www.royalgreenhouse.co.in/"
                  className="text-primary text-decoration-none"
                >
                  www.royalgreenhouse.co.in
                </a>
              </span>
            </div>
            <p>
              Hydroponic farming is not just a trend—it's the future of
              agriculture. As we face an uncertain climate and an ever-growing
              global population, hydroponic farming provides the solution we
              need to secure our food future.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Hydroponic_Farming;
