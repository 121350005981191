import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import "animate.css";

// Importing images
import g12 from "../../Assets/gallery/12.webp";
import g13 from "../../Assets/gallery/13.webp";
import g15 from "../../Assets/gallery/15.webp";
import g20 from "../../Assets/gallery/20.webp";
import g23 from "../../Assets/gallery/23.webp";
import g25 from "../../Assets/gallery/25.webp";
import g30 from "../../Assets/gallery/30.webp";
import g31 from "../../Assets/gallery/31.webp";

import ng1 from "../../Assets/gallery/amo1.webp";
import ng2 from "../../Assets/gallery/amo2.webp";
import ng3 from "../../Assets/gallery/amo3.webp";
import ng4 from "../../Assets/gallery/amo4.webp";

import ng5 from "../../Assets/gallery/bs1.webp";
import ng6 from "../../Assets/gallery/bs2.webp";
import ng7 from "../../Assets/gallery/bs3.webp";
import ng8 from "../../Assets/gallery/bs4.webp";
import ng37 from "../../Assets/gallery/bs5.webp";

import ng9 from "../../Assets/gallery/chokha1.webp";
import ng10 from "../../Assets/gallery/chokha2.webp";
import ng11 from "../../Assets/gallery/chokha3.webp";
import ng12 from "../../Assets/gallery/chokha4.webp";
import ng33 from "../../Assets/gallery/chokha5.webp";
import ng34 from "../../Assets/gallery/chokha6.webp";
import ng35 from "../../Assets/gallery/chokha7.webp";
import ng36 from "../../Assets/gallery/chokha8.webp";

import ng13 from "../../Assets/gallery/silvasa1.webp";
import ng14 from "../../Assets/gallery/silvasa2.webp";
import ng15 from "../../Assets/gallery/silvasa3.webp";
import ng16 from "../../Assets/gallery/silvasa4.webp";
import ng38 from "../../Assets/gallery/silvasa5.webp";
import ng39 from "../../Assets/gallery/silvasa6.webp";

import ng17 from "../../Assets/gallery/eg1.webp";
import ng18 from "../../Assets/gallery/eg2.webp";
import ng19 from "../../Assets/gallery/eg3.webp";
import ng20 from "../../Assets/gallery/eg4.webp";
import ng40 from "../../Assets/gallery/eg5.webp";
import ng41 from "../../Assets/gallery/eg6.webp";
import ng42 from "../../Assets/gallery/eg7.webp";
import ng43 from "../../Assets/gallery/eg8.webp";

import ng21 from "../../Assets/gallery/nd1.webp";
import ng22 from "../../Assets/gallery/nd2.webp";
import ng23 from "../../Assets/gallery/nd3.webp";
import ng24 from "../../Assets/gallery/nd4.webp";
import ng44 from "../../Assets/gallery/nd5.webp";
import ng45 from "../../Assets/gallery/nd6.webp";
import ng46 from "../../Assets/gallery/nd7.webp";
import ng47 from "../../Assets/gallery/nd8.webp";

import ng25 from "../../Assets/gallery/sha1.webp";
import ng26 from "../../Assets/gallery/sha2.webp";
import ng27 from "../../Assets/gallery/sha3.webp";
import ng28 from "../../Assets/gallery/sha4.webp";

import ng29 from "../../Assets/gallery/shuklaNVPH1.webp";
import ng30 from "../../Assets/gallery/shuklaNVPH2.webp";
import ng31 from "../../Assets/gallery/shuklaNVPH3.webp";
import ng32 from "../../Assets/gallery/shuklaNVPH4.webp";

import ng48 from "../../Assets/gallery/factory1.webp";
import ng49 from "../../Assets/gallery/factory2.webp";
import ng50 from "../../Assets/gallery/factory3.webp";
import ng51 from "../../Assets/gallery/factory4.webp";
import ng52 from "../../Assets/gallery/factory5.webp";
import ng53 from "../../Assets/gallery/factory6.webp";
import ng54 from "../../Assets/gallery/factory7.webp";
import ng55 from "../../Assets/gallery/factory8.webp";
import ng56 from "../../Assets/gallery/factory9.webp";
import ng57 from "../../Assets/gallery/factory10.webp";
import ng58 from "../../Assets/gallery/factory11.webp";
import ng59 from "../../Assets/gallery/factory12.webp";
import ng60 from "../../Assets/gallery/factory13.webp";

// Define your images and their details
const galleryData = [
  {
    imgSrc: g31,
    title: "Tamil Nadu",
    heading: "Tamil Nadu's Project",
    description:
      "We recently completed an extensive 16-acre project for Amodhini Real Estate, located in Hosur. This project primarily involved the construction of multiple NVPH (Naturally Ventilated Polyhouse) polyhouses, each standing at an impressive height of 6 meters. These state-of-the-art polyhouses are designed to support large-scale floriculture, ensuring optimal growing conditions for a variety of flowers. Our dedicated team ensured the highest quality standards were met, delivering robust and efficient structures tailored to the client’s specific agricultural needs.",
    sliderImages: [g31, ng1, ng2, ng3, ng4], // Slider images specific to this image
    alt: "Polyhouse setup in India",
  },
  {
    imgSrc: g30,
    title: "Karnataka",
    heading: "Karnataka's Project",
    description:
      "We recently completed the construction of an NVPH (Naturally Ventilated Polyhouse) polyhouse for Bharat in Electronic City, Bengaluru. This polyhouse spans 5,000 square feet and features an advanced A-frame DFT (Deep Flow Technique) hydroponic system. The combination of NVPH structure and hydroponic technology ensures optimal growing conditions for a variety of crops, enhancing both yield and quality. Our team meticulously designed and built this polyhouse to meet the specific requirements of modern, sustainable agriculture.",
    sliderImages: [g30, ng5, ng6, ng7, ng8, ng37], // Slider images specific to this image
    alt: "DFT Hydroponic setup",
  },
  {
    imgSrc: g23,
    title: "Rajasthan",
    heading: "Rajasthan's Project",
    description:
      "We recently completed the construction of a 5,000 square foot fan pad polyhouse for the corporate giant Chokha Dhani Group. This advanced polyhouse is divided into three equal sections: 33% dedicated to grow slabs for vine crops, 33% to an A-frame NFT (Nutrient Film Technique) system, and 33% to an NFT flatbed system. The integration of these diverse hydroponic systems ensures optimal growing conditions and maximizes efficiency and yield for various crops. Our team ensured the highest standards in design and construction to meet the specific agricultural needs of the Chokha Dhani Group.",
    sliderImages: [g23, ng9, ng10, ng11, ng12, ng33, ng34, ng35, ng36], // Slider images specific to this image
    alt: "Fan & Pad Polyhouse setup",
  },
  {
    imgSrc: g25,
    title: "Daman & Diu",
    heading: "Daman & Diu's Project",
    description:
      "We recently completed the construction of a 10,000 square foot NVPH (Naturally Ventilated Polyhouse) polyhouse in Diu, Daman. This polyhouse features a specialized grow slab system designed for vine crops and is equipped with a pink shade net, tailored to the client's specific requirements. Our team meticulously designed and built this polyhouse to ensure optimal growing conditions, enhancing the productivity and quality of the crops. The implementation of the pink shade net provides additional benefits, such as improved light diffusion and temperature control, catering to the precise agricultural needs of the client.",
    sliderImages: [g25, ng13, ng14, ng15, ng16, ng38, ng39], // Slider images specific to this image
    alt: "NVPH setup designed for vine crops",
  },
  {
    imgSrc: g20,
    title: "Kerala",
    heading: "Kerala's Project",
    description:
      "We successfully completed the construction of fan and pad polyhouses, each spanning 1,000 square feet, in Kerala. These polyhouses feature advanced NFT (Nutrient Film Technique) flatbed systems with triple layer beds and integrated grow lights. The design ensures optimal growing conditions by maximizing space utilization and providing consistent light and nutrient delivery. Our team delivered these state-of-the-art facilities to meet the specific agricultural needs of the region, enhancing both efficiency and crop yields.",
    sliderImages: [g20, ng17, ng18, ng19, ng20, ng40, ng41, ng42, ng43], // Slider images specific to this image
    alt: "hydroponic setup company",
  },
  {
    imgSrc: g12,
    title: "R&D Farm Hydroponics Systems and Facilities",
    heading: "Maharashtra's Project",
    description:
      "A fan and pad system covering 2,000 square feet, designed to maintain optimal temperature and humidity levels.An NVPH (Naturally Ventilated Polyhouse) of 2,500 square feet, providing a controlled environment for various hydroponic experiments.An additional NVPH dedicated to an A-frame DFT system, offering specialized conditions for this particular growing technique.A rain-protected shade net house of 13,000 square feet, designed for vine crops, blueberries, and vertical setups for ginger and turmeric.",
    sliderImages: [g12, ng21, ng22, ng23, ng24, ng44, ng45, ng46, ng47], // Slider images specific to this image
    alt: "Naturally ventilated polyhouse",
  },
  {
    imgSrc: g15,
    title: "Maharashtra",
    heading: "Maharashtra's Project",
    description:
      "We recently completed the construction of a state-of-the-art net house spanning 30,000 square feet. This facility is designed to provide an optimal growing environment for various crops. The net house, constructed with advanced materials and techniques, ensures excellent ventilation and protection while maximizing light exposure. Our dedicated team ensured that the net house meets the highest standards of quality and efficiency, tailored to the specific agricultural needs of the region.",
    sliderImages: [g15, ng25, ng26, ng27, ng28], // Slider images specific to this image
    alt: "Shade Net House",
  },
  {
    imgSrc: g13,
    title: "Karnataka",
    heading: "Karnataka's Project",
    description:
      "We recently completed the construction of a 1,000 square foot NVPH (Naturally Ventilated Polyhouse) polyhouse for the client, Shukla NVPH, in the region. The facility features advanced hydroponic systems tailored for the specific agricultural needs of the client. Our team ensured the highest standards in design and construction, providing a state-of-the-art environment for crop growth. The NVPH structure supports optimal growing conditions, enhancing both the efficiency and productivity of the agricultural operations.",
    sliderImages: [g13, ng29, ng30, ng31, ng32], // Slider images specific to this image
    alt: "Naturally ventilated polyhouse Project in India",
  },
  // {
  //   imgSrc: ng59,
  //   title: "Gujarat",
  //   heading: "Gujarat's Project",
  //   description:
  //     "We completed the construction of a 15,000 square foot fan and pad polyhouse, designed for large-scale floriculture. The polyhouse features advanced systems to control temperature and humidity, providing an optimal environment for flower cultivation. The project showcases our expertise in creating high-quality, efficient growing spaces tailored to the specific needs of the client. Our team ensured the highest standards in design and construction, delivering a robust and productive facility.",
  //   sliderImages: [
  //     ng59,
  //     ng48,
  //     ng49,
  //     ng50,
  //     ng51,
  //     ng52,
  //     ng53,
  //     ng54,
  //     ng55,
  //     ng56,
  //     ng57,
  //     ng58,
  //     ng59,
  //     ng60,
  //   ], // Slider images specific to this image
  //   alt:"NVPH project near me"
  // },
];

function Gallery() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentGallery, setCurrentGallery] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [animateTitle, setAnimateTitle] = useState(false);

  useEffect(() => {
    if (showModal && currentGallery) {
      startSlider();
      setAnimateTitle(true);
    } else {
      stopSlider();
      setAnimateTitle(false);
    }
  }, [showModal, currentGallery]);

  useEffect(() => {
    if (selectedImage !== null) {
      setCurrentGallery(
        galleryData.find((item) => item.imgSrc === selectedImage)
      );
      setCurrentIndex(0);
    }
  }, [selectedImage]);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
    setCurrentGallery(null);
    stopSlider();
    setAnimateTitle(false); // Disable title animation when modal closes
  };

  const startSlider = () => {
    const id = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % currentGallery.sliderImages.length
      );
    }, 1500); // Adjust interval time as needed (in milliseconds)
    setIntervalId(id);
  };

  const stopSlider = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const handleNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % currentGallery.sliderImages.length
    );
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + currentGallery.sliderImages.length) %
        currentGallery.sliderImages.length
    );
  };

  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Our Hydroponic Gallery
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className="text-white m-3 text-decoration-none">
                Home
              </a>
              <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Our Hydroponic Gallery
              </a>
            </div>
          </Col>
        </Row>
        <Row className="p-3">
          {galleryData.map((item, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              className="p-3"
            >
              <Card
                onClick={() => handleImageClick(item.imgSrc)}
                style={{ cursor: "pointer", position: "relative" }}
              >
                <Card.Img
                  variant="top"
                  src={item.imgSrc}
                  className="img-fluid rounded zoom-img"
                />
                <div
                  className={`gallery-title ${
                    animateTitle ? "animate__fadeInDown" : ""
                  }`}
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "white",
                    padding: window.innerWidth < 768 ? "4px 8px" : "5px 10px",
                    fontSize: window.innerWidth < 768 ? "14px" : "16px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    width: window.innerWidth < 768 ? "60%" : "40%",
                    textAlign: "center",
                  }}
                >
                  {item.title}
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal Slider */}
      <Modal show={showModal} onHide={closeModal} centered size="xl">
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 120px)",
            overflowY: "auto",
            padding: "20",
          }}
        >
          {currentGallery && (
            <div
              className="modal-slider"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                className="d-flex flex-column flex-md-row"
                style={{ flex: "1", overflow: "hidden" }}
              >
                {/* Image Container */}
                <div
                  className="modal-image"
                  style={{
                    flex: "1",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={currentGallery.sliderImages[currentIndex]}
                    className="img-fluid rounded"
                    alt="modal-img"
                    style={{
                      width: "100%",
                      maxHeight: "400px",
                      objectFit: "contain",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                  {/* Previous button */}
                  <Button
                    variant="light"
                    onClick={handlePrev}
                    className="modal-slider-btn prev-btn"
                    style={{
                      backgroundColor: "green",
                      fontSize: "24px",
                      position: "absolute",
                      top: "50%",
                      left: "10px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                  >
                    &lt;
                  </Button>
                  {/* Next button */}
                  <Button
                    variant="light"
                    onClick={handleNext}
                    className="modal-slider-btn next-btn"
                    style={{
                      backgroundColor: "green",
                      fontSize: "24px",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                  >
                    &gt;
                  </Button>
                </div>
                {/* Description Container */}
                <div
                  className="modal-description"
                  style={{
                    flex: "1",
                    overflowY: "auto",
                    padding: "10px",
                    textAlign: "justify",
                  }}
                >
                  <h2
                    className="text-center"
                    style={{ fontSize: "1.5rem", marginBottom: "10px" }}
                  >
                    {currentGallery.heading}
                  </h2>
                  <p style={{ fontSize: "1rem" }}>
                    {currentGallery.description}
                  </p>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Gallery;
